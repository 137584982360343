:root {
  --main-color: #2b3e5b;
  --secondary-color: #757070;
}

.checkbox-item {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  color: var(--main-color);
  margin-left: 0.4rem;
}

.checkbox-item p {
  font-family: Verdana;
  font-style: italic;
  font-weight: 400;
  font-size: 0.75rem;
  color: var(--secondary-color);
}

.checkbox-item__extra-text {
  color: var(--secondary-color);
  margin-top: -0.5rem;
  margin-left: 1.5rem;
}

.checkbox-item__row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-item__row label {
  margin-right: 0.5rem;
}

.checkbox-item__row p {
  font-family: Verdana;
  font-style: italic;
  font-weight: 400;
  font-size: 0.85rem;
  color: var(--secondary-color);
}

.checkbox-element {
  display: flex;
  align-items: center;
  margin-right: 0.1rem;
  vertical-align: middle;
}
