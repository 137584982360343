:root {
  --main-color: #2b3e5b;
}

.stock-notification__container {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  color: var(--main-color);
  margin-left: 0.4rem;
}
